"use client";

import clsx from "clsx";
import { useState } from "react";
import { event as gaEvent } from "@/app/lib/gtag";

export default function ChatExamples() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="card max-w-2xl bg-neutral p-2">
      <div role="tablist" className="tabs-boxed tabs mb-4 bg-base-100">
        <a
          role="tab"
          className={clsx("tab", { "tab-active": activeTab === 0 })}
          onClick={() => {
            setActiveTab(0);
            gaEvent("Clicked Expert Advice Tab", {
              event_category: "HP Chat Examples",
              event_label: "",
              value: 0,
            });
          }}
        >
          Advice
        </a>
        <a
          role="tab"
          className={clsx("tab", { "tab-active": activeTab === 1 })}
          onClick={() => {
            setActiveTab(1);
            gaEvent("Clicked Share Concerns Tab", {
              event_category: "HP Chat Examples",
              event_label: "",
              value: 0,
            });
          }}
        >
          Concerns
        </a>
        <a
          role="tab"
          className={clsx("tab", { "tab-active": activeTab === 2 })}
          onClick={() => {
            setActiveTab(2);
            gaEvent("Clicked Market Place Tab", {
              event_category: "HP Chat Examples",
              event_label: "",
              value: 0,
            });
          }}
        >
          Marketplace
        </a>
      </div>
      {activeTab === 0 && (
        <div className="tab-panel max-h-60 overflow-auto">
          <div className="chat chat-end">
            <div className="avatar chat-image">
              <div className="w-10 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="42px"
                  viewBox="0 -960 960 960"
                  width="42px"
                  className="fill-base-200"
                >
                  <path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                </svg>
              </div>
            </div>
            <div className="chat-header">
              ExpectingMom <time className="text-xs opacity-50">4:47 PM</time>
            </div>
            <div className="chat-bubble max-w-5xl bg-[#e5f4f3] text-left">
              In the Elterngeld application at some point I get asked about my
              receiving Mutterschaftsgeld, which I am. So I answered
              &ldquo;yes&rdquo;. Then I get asked &ldquo;Within what time period
              do you have this right&rdquo; and I answered by choosing the
              period from 6 weeks prior until 8 weeks after my due date. Then
              below I get asked again &ldquo;When are you likely to receive
              Mutterschaftsgeld from your employer?&rdquo;. The answer is the
              same period as I wrote above, I guess! I could not think of any
              different answer 🤔 But I started to doubt about it as this way
              I&apos;m answering 2 questions with the same answer.
            </div>
          </div>
          <div className="chat chat-end">
            <div className="avatar chat-image">
              <picture>
                <img
                  src="https://images.ctfassets.net/mzvrb2yl4z77/3tlENf4BwYyRO9CEYcI96k/4d5e0f0c67d7f2f132c56fb7fe0df2ce/lisaicon.png?w=39&fm=jpg&q=100&fit=fill"
                  alt="avatar"
                  height={40}
                  width={40}
                />
              </picture>
            </div>
            <div className="chat-header">
              LisaKietzee <time className="text-xs opacity-50">10:25 AM</time>
            </div>
            <div className="chat-bubble max-w-5xl bg-[#e5f4f3] text-left">
              Hi @ExpectingMom , I&apos;ve asked Elodie to chime in – stay
              tuned!
            </div>
          </div>
          <div className="chat chat-end">
            <div className="avatar chat-image">
              <div className="w-10 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="42px"
                  viewBox="0 -960 960 960"
                  width="42px"
                  className="fill-accent"
                >
                  <path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                </svg>
              </div>
            </div>
            <div className="chat-header">
              Elodie Baby in Berlin <b>(Expert)</b>{" "}
              <time className="text-xs opacity-50">10:38 AM</time>
            </div>
            <div className="chat-bubble max-w-5xl bg-[#e5f4f3] text-left">
              @ExpectingMom they are asking for the period after birth only so
              to be really precise you need to write from &ldquo;date of
              birth&rdquo; to the end of your Mutterschutz (which is either 8
              weeks after birth or the due date - whichever is the latest). And
              then the answer about your employer contribution should be the
              same dates (unless your employment ended in between). Hope this
              helps!
            </div>
          </div>
        </div>
      )}
      {activeTab === 1 && (
        <div className="tab-panel max-h-60 overflow-auto">
          <div className="chat chat-end">
            <div className="avatar chat-image">
              <div className="w-10 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="42px"
                  viewBox="0 -960 960 960"
                  width="42px"
                  className="fill-base-200"
                >
                  <path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                </svg>
              </div>
            </div>
            <div className="chat-header">
              NewMom <time className="text-xs opacity-50">9:17 AM</time>
            </div>
            <div className="chat-bubble max-w-5xl bg-[#e5f4f3] text-left">
              Question on babysitters. My husband and I are considering getting
              someone for about 8 hours a week just to give us some breaks with
              our 3 month old. I have mixed feelings about it, including feeling
              &ldquo;guilty&rdquo; about having someone and paying them while
              I&apos;m on maternity leave and not working. I was wondering how
              others deal with this? Do you have some sort of support to get a
              break during the week paid or unpaid? Or do you just kind of grind
              it out? Thanks!
            </div>
          </div>
          <div className="chat chat-end">
            <div className="avatar chat-image">
              <div className="w-10 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="42px"
                  viewBox="0 -960 960 960"
                  width="42px"
                  className="fill-base-200"
                >
                  <path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                </svg>
              </div>
            </div>
            <div className="chat-header">
              DadOf1YO <time className="text-xs opacity-50">9:38 AM</time>
            </div>
            <div className="chat-bubble max-w-5xl bg-[#e5f4f3] text-left">
              I consider break time for parents one of the best things money can
              buy during this period. We hire a nanny 2 to 3 times a week, ~4h
              each time. Even if one of us is on full-time parental leave, they
              need their break, too 🙂
            </div>
          </div>
          <div className="chat chat-end">
            <div className="avatar chat-image">
              <picture>
                <img
                  src="https://images.ctfassets.net/mzvrb2yl4z77/3tlENf4BwYyRO9CEYcI96k/4d5e0f0c67d7f2f132c56fb7fe0df2ce/lisaicon.png?w=39&fm=jpg&q=100&fit=fill"
                  alt="avatar"
                  height={40}
                  width={40}
                />
              </picture>
            </div>
            <div className="chat-header">
              LisaKietzee <time className="text-xs opacity-50">9:42 AM</time>
            </div>
            <div className="chat-bubble max-w-5xl bg-[#e5f4f3] text-left">
              I totally feel you! And I agree with @DadOf1YO – taking care of a
              child full-time is super exhausting and it&apos;s good to take
              breaks when you need them for yourself, for couple time... And
              then it may feel even better when you&apos;re back with your baby
              🙂
            </div>
          </div>
        </div>
      )}
      {activeTab === 2 && (
        <div className="tab-panel max-h-60 overflow-auto">
          <div className="chat chat-end">
            <div className="avatar chat-image">
              <div className="w-10 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="42px"
                  viewBox="0 -960 960 960"
                  width="42px"
                  className="fill-base-200"
                >
                  <path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                </svg>
              </div>
            </div>
            <div className="chat-header">
              ExpectingMom <time className="text-xs opacity-50">2:43 PM</time>
            </div>
            <div className="chat-bubble max-w-5xl bg-[#e5f4f3] text-left">
              <div className="text-left">
                Good morning, I&apos;m expecting my first daughter🎀
                <br />
                I&apos;m looking for:
                <ul className="ml-4 list-disc">
                  <li>
                    a used stroller-trio in good conditions, ideally of the
                    brands: Joolz, Bugaboo, Cybex or Inglesina. Including wanne,
                    sitz, accessories etc. With receipt.
                  </li>
                  <li>
                    a next-to-2 bed, ideally of the brands: Chicco or CAM a
                    Badewanne
                  </li>{" "}
                  <li>a changing table (Wicheltisch)</li>
                  <li>
                    a baby nest (cushion to lay the baby) used newborn clothes
                    for summer (she&apos;s due in mid July ☺️)
                  </li>
                </ul>{" "}
                … or any other potential accessories that you reckon are useful
                for newborns, you no longer use and are in good conditions.
                Please let me know if you have anything of these to sell. Thanks
                / Grazie / Dankeschön! 🤗
              </div>
            </div>
          </div>
          <div className="chat chat-end">
            <div className="avatar chat-image">
              <div className="w-10 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="42px"
                  viewBox="0 -960 960 960"
                  width="42px"
                  className="fill-base-200"
                >
                  <path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                </svg>
              </div>
            </div>
            <div className="chat-header">
              NewMom <time className="text-xs opacity-50">3:08 PM</time>
            </div>
            <div className="chat-bubble max-w-5xl bg-[#e5f4f3] text-left">
              Hi @ExpectingMom
              <br />I have a baby nest (perfect condition - for free)
              <br />
              And quite a few 0-3 month bodies (we can agree on a price based on
              what and how many you take.. I won&apos;t want anything crazy :-))
              I live in 12435
              <br />
              Also by the summer our baby bay original will be ready for another
              home. In case you are willing to explore other brands.. I would
              give you the bed, mattress, new fitted sheets and net for €150 or
              your best offer
              <br />
              Ah and a baby Björn bouncer our little girl is obsessed with. For
              50 € .. Would you like that? Our baby won&apos;t fit in it by July
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
